<template>
  <div id="faq">
    <div
      class="banner cvr-bg smaller-title"
      style="background-image: url('/img/dummy/faq/1.jpg');"
    >
      <div class="wrapper">
        <h1>{{ $t("faq.Title") }}</h1>
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of banner -->
    <div class="inner-faq">
      <div class="search">
        <div class="wrapper">
          <h2>Any Questions?</h2>
          <form @submit.prevent="getQuestion">
            <i class="cvr-bg"></i>
            <input
              type="text"
              :placeholder="$t('faq.Placeholder')"
              v-model="search"
            />
            <button type="submit" class="button">
              {{ $t("faq.Button") }}
            </button>
          </form>
        </div>
        <!-- eend of form -->
      </div>
      <!-- end of search -->

      <div class="main">
        <div class="wrapper rowflex">
          <aside class="sidebar">
            <div class="holder">
              <a href="#" class="close-trigger cvr-bg"></a>
              <h3>
                {{ $t("faq.Categories") }}
              </h3>
              <ul>
                <template v-for="(category, index) in categories">
                  <li
                    :class="{ current: activeCategory == category }"
                    :key="index"
                  >
                    <a href="#" @click="activeCategory = category">
                      {{ category.name }}
                    </a>
                  </li>
                </template>
              </ul>
            </div>
            <!-- end of holder -->
          </aside>
          <article class="content">
            <h2>
              {{ $t("faq.Articles") }}
              <a href="#" class="cat-trigger">
                {{ activeCategory.name }}
              </a>
            </h2>
            <div class="list">
              <template v-for="(question, index) in questions">
                <div
                  class="accord-item"
                  :key="index"
                  @click="toogleQuestion(question)"
                  :class="{ expanded: activeQuestion == question }"
                >
                  <div class="acc-head">
                    <h3>
                      {{ question.question }}
                    </h3>
                  </div>
                  <div
                    class="acc-body"
                    :style="{
                      display: activeQuestion == question ? 'block' : 'none'
                    }"
                  >
                    <div class="format-text" v-html="question.response"></div>
                    <!-- end of format text -->
                  </div>
                  <!-- end of acc body -->
                </div>
              </template>
            </div>
            <!-- end of list -->
          </article>
          <!-- end of contrnt -->
        </div>
        <!-- end of wrapper -->
      </div>
      <!-- end of main -->
    </div>
    <!-- end of inner faq -->
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "Faq",
  metaInfo: {
    title: "Frequently Asked Questions"
  },
  data() {
    return {
      categories: [],
      activeCategory: {},
      questions: [],
      search: "",
      activeQuestion: null
    };
  },
  watch: {
    activeCategory() {
      this.activeQuestion = null;
      this.getQuestion();
    }
  },
  mounted() {
    this.getCategories();

    $(".inner-faq .content h2 .cat-trigger").on("click", function(e) {
      e.preventDefault();
      $(".inner-faq .sidebar").fadeIn("fast", function() {
        setTimeout(function() {
          $(".inner-faq .sidebar .holder").slideDown("fast");
        }, 100);
      });
    });

    $(".inner-faq .sidebar .close-trigger").on("click", function(e) {
      e.preventDefault();
      $(".inner-faq .sidebar .holder").slideUp("fast", function() {
        setTimeout(function() {
          $(".inner-faq .sidebar").fadeOut("fast");
        }, 100);
      });
    });
  },
  methods: {
    getCategories() {
      this.$axios
        .get(`/misc/categories`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.categories = response.data.data;
          this.activeCategory = response.data.data[0];
        });
    },
    getQuestion() {
      this.$axios
        .get(`/faqs/category/${this.activeCategory.id}`, {
          params: {
            lang: this.$store.state.locale.lang,
            question: `like:%${this.search}%`
          }
        })
        .then(response => {
          this.questions = response.data.data.rows;
        });
    },
    toogleQuestion(question) {
      if (this.activeQuestion == question) {
        this.activeQuestion = null;
      } else {
        this.activeQuestion = question;
      }
    }
  }
};
</script>
